import { createGlobalStyle } from 'styled-components'
import Theme from './Theme'

const GlobalStyle = createGlobalStyle<{ theme: Theme }>`
  @font-face {
	font-family: "Inter";
	src: local("Inter"),
	url("/Inter-VariableFont_slnt,wght.ttf") format("truetype-variations");
  }

  html, body, #root {
	margin: 0;
	background-color: ${props => props.theme.colors.rootBackgroundColor};
	min-height: 100vh;
	font-family: "Inter", "Trebuchet MS";
	font-size: 14px;
	text-rendering: optimizeLegibility;
  }

  input, textarea, button {
	font-family: "Inter", "Trebuchet MS";
  }

  ul {
	line-height: initial;
	margin: 0;
  }

  textarea {
	font-size: 14px;
  }

  #root, code {
    white-space: pre-wrap;
  }

  pre {
    margin: 0;
  }
`

export {
	GlobalStyle,
}
