import { Link, LinkProps } from 'react-router-dom'
import styled, { CSSObject } from 'styled-components'
import { fontSize, FontSizeProps, fontWeight } from 'styled-system'
import Theme from '../theme/Theme'
import { View, ViewProps } from '../View'

export interface TextProps extends ViewProps, FontSizeProps {
	color: keyof Theme['colors']
	fontWeight?: CSSObject['fontWeight']
	textWrap?: CSSObject['textWrap']
}

export const Text = styled(View).attrs({ as: styled.span`` })<TextProps>(
	{
	},
	fontSize,
	fontWeight,
	({ textWrap }) => ({ textWrap }),
)

Text.defaultProps = {
	lineHeight: 1.5,
}

export const TextLink = styled(View).attrs<ViewProps>(props => ({ as: props.as ?? Link }))<LinkProps>(
	{
		cursor: 'pointer',
		display: 'inline',
		textDecorationLine: 'none',
		'&:hover': {
			textDecorationLine: 'underline',
		},
	},
	({ color, theme }) => ({
		color: color ?? theme.colors.primary,
	}),
)

export const PreText = styled.pre(
	{
		padding: '5px',
		margin: '-5px 0 -5px 0',
		borderRadius: '4px',
		width: 'fit-content',
	},
	({ theme }) => ({
		backgroundColor: theme.colors.cardInputBackground,
	}),
)
