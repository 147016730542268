import { MouseEventHandler, PropsWithChildren, PropsWithRef } from 'react'
import styled, { CSSObject } from 'styled-components'
import { StyledTarget } from 'styled-components/dist/types'
import {
	background,
	BackgroundProps,
	border,
	BorderProps,
	boxShadow,
	BoxShadowProps,
	color,
	ColorProps,
	flexbox,
	FlexboxProps,
	grid,
	GridProps,
	height,
	HeightProps,
	layout,
	LayoutProps,
	letterSpacing,
	LetterSpacingProps,
	lineHeight,
	LineHeightProps,
	margin,
	MarginProps,
	padding,
	PaddingProps,
	position,
	PositionProps,
	system,
	textAlign,
	TextAlignProps,
	width,
	WidthProps,
} from 'styled-system'

export interface ViewProps extends
	BackgroundProps,
	BorderProps,
	BoxShadowProps,
	ColorProps,
	FlexboxProps,
	GridProps,
	HeightProps,
	LayoutProps,
	LetterSpacingProps,
	LineHeightProps,
	MarginProps,
	PaddingProps,
	PositionProps,
	TextAlignProps,
	WidthProps
{
	as?: StyledTarget<any>
	aspectRatio?: CSSObject['aspectRatio']
	backdropFilter?: CSSObject['backdropFilter']
	children?: PropsWithChildren<unknown>['children']
	className?: string
	color?: ColorProps['color'] & string
	cursor?: CSSObject['cursor']
	draggable?: HTMLElement['draggable']
	filter?: CSSObject['filter']
	float?: CSSObject['float']
	gap?: number | string
	gridColumnStart?: number | string
	gridColumnEnd?: number | string
	gridRowStart?: number | string
	gridRowEnd?: number | string
	elevation?: number
	inset?: CSSObject['inset']
	isolation?: CSSObject['isolation']
	pointerEvents?: CSSObject['pointerEvents']
	onMouseDown?: MouseEventHandler<HTMLElement>
	onMouseUp?: MouseEventHandler<HTMLElement>
	ref?: PropsWithRef<any>['ref']
	textOverflow?: CSSObject['textOverflow']
	textTransform?: CSSObject['textTransform']
	transform?: string
	transition?: CSSObject['transition']
	whiteSpace?: CSSObject['whiteSpace']
}

const cursor = system({ cursor: true })
const pointerEvents = system({ pointerEvents: true })

export const View = styled.span<ViewProps>(
	{
		boxSizing: 'border-box',
		display: 'flex',
		transition: 'all 0.15s',
		transitionTimingFunction: 'ease-in-out',
	},
	background,
	border,
	boxShadow,
	color,
	cursor,
	flexbox,
	grid,
	height,
	layout,
	letterSpacing,
	lineHeight,
	margin,
	padding,
	pointerEvents,
	position,
	textAlign,
	width,
	({ aspectRatio }) => ({ aspectRatio }),
	({ backdropFilter }) => ({ backdropFilter }),
	({ elevation = 0, theme }) => ({ boxShadow: theme.shadows[elevation - 1] }),
	({ filter }) => ({ filter }),
	({ gap }) => ({ gap }),
	({ gridColumnStart }) => ({ gridColumnStart }),
	({ gridColumnEnd }) => ({ gridColumnEnd }),
	({ gridRowStart }) => ({ gridRowStart }),
	({ gridRowEnd }) => ({ gridRowEnd }),
	({ inset }) => ({ inset }),
	({ isolation }) => ({ isolation }),
	({ textOverflow }) => ({ textOverflow }),
	({ textTransform }) => ({ textTransform }),
	({ transform }) => ({ transform }),
	({ transition }) => ({ transition }),
	({ whiteSpace }) => ({ whiteSpace }),
)
