import { Chart } from '@withdiver/components/src/charts/Chart'
import { TableChart } from '@withdiver/components/src/charts/TableChart'
import { Dashboard, DashboardItem } from '@withdiver/components/src/Dashboard'
import ModalHandler from '@withdiver/components/src/modals/ModalHandler'
import React from 'react'

function DashboardPreview() {
	return (
		<ModalHandler zIndex={1000}>
			<Dashboard
				columns={6}
				rows={3}
				height="50vh"
				width="80vw"
				borderWidth="20px"
				borderStyle="solid"
				boxShadow="#222 0px 0px 0px 1px"
				borderColor="currentColor"
				backgroundColor="currentColor"
				gap="20px"
				mx="10px"
				opacity={0.6}
				pointerEvents="none"
			>
				<DashboardItem
					padding={1}
					x={1}
					y={1}
					width={2}
					height={2}
				>
					<Chart
						data={{
							datasets: [
								{
									data: [75, 150, 120, 160, 140, 200],
									backgroundColor: '#5500aa44',
									borderRadius: 6,
									label: 'Revenue',
								}
							],
							labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
						}}
						type="bar"
					/>
				</DashboardItem>
				<DashboardItem
					padding={1}
					x={3}
					y={1}
					width={2}
				>
					<TableChart
						data={{
							columns: ['Rating', 'Comment'],
							rows: [
								['5 ⭐', 'I love your product.'],
								['5 ⭐', 'Wish I had found you sooner.'],
								['3 ⭐', 'If only you supported screen sharing.'],
							],
						}}
					/>
				</DashboardItem>
				<DashboardItem
					padding={1}
					x={5}
					y={1}
				>
					{''}
				</DashboardItem>
				<DashboardItem
					padding={1}
					x={6}
					y={1}
				>
					{''}
				</DashboardItem>
				<DashboardItem
					padding={1}
					x={3}
					y={2}
				>
					{''}
				</DashboardItem>
				<DashboardItem
					padding={1}
					x={4}
					y={2}
				>
					{''}
				</DashboardItem>
				<DashboardItem
					padding={1}
					x={5}
					y={2}
					width={2}
					height={2}
				>
					<Chart
						data={{
							datasets: [
								{
									data: [75, 150, 120, 160, 140, 200],
									backgroundColor: '#236cf788',
									borderRadius: 6,
									label: 'Signups',
									stack: '1',
								},
								{
									data: [-10,-10,-10,-10,-10,-10],
									backgroundColor: '#ff813288',
									borderRadius: 6,
									label: 'Churns',
									stack: '1',
								},
								{
									data: [65,140,110,150,130,190],
									backgroundColor: '#01c06277',
									borderColor: '#01c06288',
									label: 'Net signups',
									stack: '1',
									type: 'line',
									order: -1,
								},
							],
							labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
						}}
						type="bar"
					/>
				</DashboardItem>
				<DashboardItem
					padding={1}
					x={1}
					y={3}
				>
					{''}
				</DashboardItem>
				<DashboardItem
					padding={1}
					x={2}
					y={3}
				>
					{''}
				</DashboardItem>
			</Dashboard>
		</ModalHandler>
	)
}

export {
	DashboardPreview,
}
